@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

$red: #ff1e20;
$active-red: #cf0002;
$blue: dodgerblue;
$active-blue: #0073e2;
$focus-blue: #e2f1ff;
$white: white;
$gray-background: #eee;
$disabled-gray-background: #ccc;

@mixin button($background-color: $blue, $color: $white) {
    background-color: $background-color;
    border-radius: 1.5rem;
    color: $color;
    font-weight: 600;
    padding: 0.5rem 1.5rem;

    &:active {
        background-color: $active-blue;
    }

    &:disabled {
        background-color: $disabled-gray-background;
    }
}

* {
    border: 0;
    box-sizing: inherit;
    font: inherit;
    line-height: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1.0;
    margin: 0.5rem;
}

a {
    color: dodgerblue;
    font-weight: 600;
    text-decoration: none;
}

button,
input[type="submit"] {
    @include button;
}

button.destructive,
input[type="submit"].destructive {
    background-color: $red;
    color: $white;

    &:active {
        background-color: $active-red;
    }
}

h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1.5rem 0;
    text-align: center;
}

h3 {
    font-weight: 700;
    margin: 1.5rem 0;
    text-align: center;
}

input {
    display: block;
}

form {
    display: flex;
    flex-direction: column;
}

form input[type="submit"] {
    align-self: flex-end;
}

form > * {
    margin: 0.5rem;
    margin-bottom: 0;
}

form > *:last-child {
    margin-bottom: 0.5rem;
}

form label {
    display: inline-block;
    padding-left: 0.5rem;
    text-transform: uppercase;
    color: #999;
    font-size: 0.75rem;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.333);
}

input[type="text"],
input[type="password"],
input[type="datetime-local"] {
    background-color: $gray-background;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

input[type="text"]:focus,
input[type="password"]:focus {
    background-color: $focus-blue;
}

a.button {
    border: 2px solid $blue;
    border-radius: 1.5rem;
    color: $blue;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    text-align: center;
}

table.data {
    border: 1px solid #ccc;
    margin: 1.5rem 0;
    width: 100%;

    thead {

        td {
            background-color: #f0f0f0;
            text-align: center;
            padding: 0.5rem;
            text-transform: uppercase;
            color: #999;
            font-size: 0.75rem;
            font-weight: 600;
        }
    }

    tbody {

        td {
            background-color: #f6f6f6;
            padding: 0.25rem;
        }
    
        td:first-child {
            text-align: right;
            text-transform: uppercase;
            color: #666;
            font-size: 0.75rem;
            padding-right: 0.5rem;
        }
    
        td:last-child {
            line-height: 1.333;
            text-align: left;
        }
    }
}

.error-list {
    margin: 1.5rem;

    h3 {
        color: $red;
        text-align: left;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    ul {
        margin-left: 1.75rem;
    }
}

.error {
    color: $red;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5rem 0;

    > * {
        margin-bottom: 0.5rem;
    }

    a {
        padding: 0.5rem 1.5rem;
    }
}

#root {
    margin: 0 auto;
    max-width: 428px;
}

#account form,
#my-account form {
    border: 1pt dashed #ccc;
}

#account form.button,
#my-account form.button {
    border: 0;
    margin: 0;

    input[type="submit"] {
        margin-top: 0;
    }
}

form + form {
    margin-top: 1.5rem;
}
